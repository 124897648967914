<template>
  <div class="container-page">

    <!--  -->
    <div class="section-page recruit">
      <div class="container">
        <h3 class="h3">加入我们</h3>
        <p class="sub-tit">加入我们-创造未来</p>

        <div class="recruit-list">
          <div class="grid" :class="{toggle:item.toggle}" v-for="(item,index) of recruitList" :key="index">
            <div class="grid-info">
              <strong class="tit">{{item.title}}</strong>
              <div class="grid-info-cont">
                <strong class="sub-cont-tit">职位描述</strong>
                <div class="sub-cont-info" v-html="item.desc"></div>
                <div class="email">投递邮箱: {{item.email}}</div>
              </div>
            </div>
            <div class="grid-operate">
              <a href="javascript:;" title="" @click="handleToggle(item)">{{item.toggle?"收起":"查看详情"}}</a>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "recruitComponentIndex",
  components: {

  },
  data() {
    return {
      recruitList:[
        {
          title: 'java开发高级工程师',
          desc: `<p>岗位要求：1、计算机相关专业本科及以上学历，5年以上开发经验；</p>
<p>2、扎实的Java开发功底，理解IO、多线程、集合、并发包，对JVM原理有深刻了解；</p>
<p>3、熟悉MyBatis、SpringMVC、SpingCloud、Dubbo、Hadoop生态(HDSF、Spark、ES、Hive)等技术；</p>
<p>5、熟悉MySQL、Oracle数据库使用；</p>
<p>6、有分布式、微服务相关项目架构的搭建经验和开发经验、熟练使用Redis、RabiitMQ、ES、Zookeeper等中间件；</p>
<p>7、有交强的业务理解能力，能独立完成系统分支模块设计与开发；</p>
<p>8、熟练软件开发流程、具有良好的编码习惯；具备编写软件设计文档的能力；</p>
<p>9、具备良好的执行力，学习力和沟通能力，强烈的责任心、成长意识和团队协作意识，有一定的抗压力能、性格良好为人谦虚友善。</p>
<p>10、有酒店管理系统开发经验者优先。</p>`,
          email: '3411099@qq.com',
          toggle:false,
        },
        {
          title: '前端开发工程师',
          desc: `<p>1、统招本科及以上学历，5年以上WEB前端开发从业经验、熟悉前端开发标准、对前端知识语义化、浏览器兼容性问题有深入了解。</p>
<p>2、精通DIV、CSS和W3C标准，HTML5和移动端开发，能熟练运用HTML5、CSS进行合理的PC端网页制作和移动端H5项目开发，深刻理解各主流浏览器中间的兼容性，能让网页格式适应各款各大小的手机。</p>
<p>3、熟悉前端的常用框架，vue、react、taro、mpvue、jquery、bootstrap并熟悉其原理，有良好的程序设计和架构能力。</p>
<p>4、需求与设计不合理的地方，要及时好设计师与产品沟通反馈。</p>`,
          email: '3411099@qq.com',
          toggle:false,
        },
        {
          title: 'ios开发工程师',
          desc: `<p>1、计算机相关专业专科及以上学历，4年以上移动端产品开发经验；</p>
<p>2、精通uni-app开发框架，具有跨平台（iOS/Android）APP的实际项目开发经验；</p>
<p>3、具有HTML、CSS3、Javascript、网络请求等相关方面的技术基础；</p>
<p>4、精通VUE框架，具有echart可视化组件、地图的开发经验；</p>
<p>5、根据产品经理的需求和UI的设计进行页面开发、功能开发；</p>
<p>6、拥有独立解决问题的能力，能够预见一些技术陷阱，并提出并完成解决方案。</p>
<p>7、具有良好的学习创新能力，强大的责任心与团队精神。</p>
<p>8、熟练软件开发流程、具有良好的编码习惯；具备编写软件设计文档的能力；</p>
<p>9、具备良好的执行力，学习力和沟通能力，强烈的责任心、成长意识和团队协作意识，有一定的抗压力能、性格良好为人谦虚友善。</p>`,
          email: '3411099@qq.com',
          toggle:false,
        }
      ],
    };
  },
  mounted() {
      // 默认第一个展开
      this.recruitList[0].toggle =  true
  },
  methods: {
    // 职位伸缩
    handleToggle(item){
      item.toggle = !item.toggle
    },
  }
};
</script>

<style lang="scss" scoped>
  .recruit-list{
    margin-top: 25px;
    .grid{
      background: #fff;
      box-shadow: 0px 2px 14px 6px #E3EBF8;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      padding: 35px;
      text-align: left;
      margin-bottom: 20px;
      height: 165px;
      overflow: hidden;
      .grid-info{
        flex: 1;
        .tit{
          font-size: 21px;
          color: #252440;
        }
        .grid-info-cont{
          margin-top: 35px;
          .sub-cont-tit{
            font-size: 18px;
          }
          .sub-cont-info{
            margin-top: 15px;
            font-size: 14px;
            color: #9FA2B3;
            line-height: 23px;
            &>p{

            }
          }
          .email{
            margin-top: 40px;
            font-size: 14px;
            color: #ACAFBF;
          }
        }
      }
      .grid-operate{
        width: 70px;
        text-align: right;
        a{
          text-decoration: none;
          font-size: 16px;
          font-weight: bold;
          color: #3D67F1;
        }
      }
      &.toggle{
        height: auto;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {

  }
  @media screen and (max-width: 767px) {
    .recruit-list{
      margin-top: 20px;
      .grid{
        padding: 10px;
        display: block;
        position: relative;
        .grid-info{
          .tit{
            font-size: 18px;
          }
          .grid-info-cont{
            margin-top: 20px;
            .sub-cont-tit{
              font-size: 16px;
            }
          }
        }
        .grid-operate{
          position: absolute;
          right: 10px;
          top: 10px;
          a{
            font-size: 14px;
          }
        }
      }
    }
  }

</style>
