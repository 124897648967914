<template>
  <div class="container-page">

    <!-- banner -->
    <header-component-index :bannerImg="aboutBannerImg">
      <div class="banner-anchor-point animate__animated animate__bounceInUp">
        <a href="javascript:;" title="" v-for="(item,index) of anchorPointList" :key="index" :class="{active:currentIndex == index}" @click="anchorPointChange(item,index)" >{{item.name}}</a>
      </div>
    </header-component-index>


    <!-- 关于我们 -->
    <about-component-index id="about0"></about-component-index>

    <!-- 我们能做什么 -->
    <we-do-component-index></we-do-component-index>

    <!-- 招聘 -->
    <recruit-component-index  id="about1"></recruit-component-index>

    <!-- 公司地址 -->
    <address-component-page-index id="about2"></address-component-page-index>
    <!-- 合作与了解 -->
    <contact-component-index id="about3"></contact-component-index>

    <!-- 底部 -->
    <footer-component-index></footer-component-index>


  </div>
</template>

<script>

  // assets
  import aboutBannerImg from '@/assets/banner-about.png'

  // components
  import HeaderComponentIndex from "../components/header";
  import ContactComponentIndex from "../components/contact";
  import FooterComponentIndex from "../components/footer";
  import AddressComponentPageIndex from "../components/address";
  import AboutComponentIndex from "../components/about";
  import WeDoComponentIndex from "../components/weDo";
  import RecruitComponentIndex from "../components/recruit";
  export default {
    name: "aboutPageIndex",
    components: {
      RecruitComponentIndex,
      WeDoComponentIndex,
      AboutComponentIndex,
      AddressComponentPageIndex,

      FooterComponentIndex,
      ContactComponentIndex,
      HeaderComponentIndex,
    },
    data() {
      return {
        aboutBannerImg,
        currentIndex:0,
        anchorPointList:[
          {name:'关于我们'},
          {name:'加入我们'},
          {name:'联系我们'},
          {name:'合作与了解'},
        ],


      }
    },
    methods: {
      // 锚点链接点击
      anchorPointChange(item,index){
        this.currentIndex = index
        document.querySelector('#about' + index).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      },

    }
  }
</script>

<style lang="scss" scoped>
  .banner-anchor-point{
    z-index: 10 ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    height: 64px;
    line-height: 64px;
    background: #3D67F1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 0 40px;
    margin-top: 580px;
    a{
      position: relative;
      font-size: 16px;
      color: #ddd;
      text-decoration: none;
      &.active{
        color: #fff;
        font-weight: bold;
      }
      &:after{
        position: absolute;
        top: 20px;
        right:-44px;
        content: "";
        width: 1px;
        height: 23px;
        background:#FFFFFF;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .banner-anchor-point{
      margin-top: 435px;
    }
  }
  @media screen and (max-width: 767px) {
    .banner-anchor-point{
      margin-top: 280px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      a{
        font-size: 12px;
        width: 25%;
        &:after{
          right: 0;
          top: 14px;
          height: 12px;
        }
      }
    }
  }

</style>
